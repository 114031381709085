































































import {
  Component, Vue, Prop,
} from 'vue-property-decorator';

import moment from 'moment';

import Thumb from '@/components/Thumb/index.vue';

import {
  Redaction as IRedaction,
} from '../../../../interfaces/IRedactionPreview';

@Component({
  components: {
    Thumb,
  },
})
export default class HeaderAThousandRedaction extends Vue {
  @Prop({ required: true }) isActiveCorrection!: boolean;
  @Prop({ required: true }) redaction!: IRedaction;

  setOptionMenu(value: boolean) {
    this.$emit('setOptionMenu', value);
  }

  formatTimeRedaction(time: number) {
    return moment.utc(time * 1000).format('HH:mm:ss');
  }
}
